import React, { memo, useEffect, useState } from "react";
import * as FileSystem from "expo-file-system";
import { Image } from "react-native";

const findExt = filename => {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

const checkImageInCache = async uri => {
    try {
        return await FileSystem.getInfoAsync(uri);
    } catch (err) {
        return false;
    }
}

const cacheImage = async (link, localUrl, callback) => {
    try {

        const downloadImage = FileSystem.createDownloadResumable(
            link, localUrl, {}, callback
        );

        return (await downloadImage.downloadAsync()).uri;

    } catch (error) {

        console.log("errrrrr: ", error);

        return false;
    }
}

export default memo(({ uri, cacheKey, style, ...props }) => {

    const encodedLink = encodeURI(uri);
    const [imgUrl, setUrl] = useState(`${FileSystem.cacheDirectory}${cacheKey}`);

    useEffect(() => {

        (async () => {

            // let imgExt = findExt(encodedLink);
            // if (!imgExt || imgExt.length < 1) { return };

            const cacheFileUri = `${FileSystem.cacheDirectory}${cacheKey}`;
            let imgInCache = await checkImageInCache(cacheFileUri);

            if (!imgInCache.exists) {
                let cached = await cacheImage(encodedLink, cacheFileUri, () => { });
                if (cached) { setUrl(`${cacheFileUri}/m`); setUrl(cached); }
            }
        })();

    }, []);

    return <Image source={{ uri: imgUrl }} style={style} {...props} />
});