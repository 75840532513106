"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("./helpers");
const defaultStyles = [
    [`aspect-square`, (0, helpers_1.complete)({ aspectRatio: 1 })],
    [`aspect-video`, (0, helpers_1.complete)({ aspectRatio: 16 / 9 })],
    [`items-center`, (0, helpers_1.complete)({ alignItems: `center` })],
    [`items-start`, (0, helpers_1.complete)({ alignItems: `flex-start` })],
    [`items-end`, (0, helpers_1.complete)({ alignItems: `flex-end` })],
    [`items-baseline`, (0, helpers_1.complete)({ alignItems: `baseline` })],
    [`items-stretch`, (0, helpers_1.complete)({ alignItems: `stretch` })],
    [`justify-start`, (0, helpers_1.complete)({ justifyContent: `flex-start` })],
    [`justify-end`, (0, helpers_1.complete)({ justifyContent: `flex-end` })],
    [`justify-center`, (0, helpers_1.complete)({ justifyContent: `center` })],
    [`justify-between`, (0, helpers_1.complete)({ justifyContent: `space-between` })],
    [`justify-around`, (0, helpers_1.complete)({ justifyContent: `space-around` })],
    [`justify-evenly`, (0, helpers_1.complete)({ justifyContent: `space-evenly` })],
    [`content-start`, (0, helpers_1.complete)({ alignContent: `flex-start` })],
    [`content-end`, (0, helpers_1.complete)({ alignContent: `flex-end` })],
    [`content-between`, (0, helpers_1.complete)({ alignContent: `space-between` })],
    [`content-around`, (0, helpers_1.complete)({ alignContent: `space-around` })],
    [`content-stretch`, (0, helpers_1.complete)({ alignContent: `stretch` })],
    [`content-center`, (0, helpers_1.complete)({ alignContent: `center` })],
    [`self-auto`, (0, helpers_1.complete)({ alignSelf: `auto` })],
    [`self-start`, (0, helpers_1.complete)({ alignSelf: `flex-start` })],
    [`self-end`, (0, helpers_1.complete)({ alignSelf: `flex-end` })],
    [`self-center`, (0, helpers_1.complete)({ alignSelf: `center` })],
    [`self-stretch`, (0, helpers_1.complete)({ alignSelf: `stretch` })],
    [`self-baseline`, (0, helpers_1.complete)({ alignSelf: `baseline` })],
    [`direction-inherit`, (0, helpers_1.complete)({ direction: `inherit` })],
    [`direction-ltr`, (0, helpers_1.complete)({ direction: `ltr` })],
    [`direction-rtl`, (0, helpers_1.complete)({ direction: `rtl` })],
    [`hidden`, (0, helpers_1.complete)({ display: `none` })],
    [`flex`, (0, helpers_1.complete)({ display: `flex` })],
    [`flex-row`, (0, helpers_1.complete)({ flexDirection: `row` })],
    [`flex-row-reverse`, (0, helpers_1.complete)({ flexDirection: `row-reverse` })],
    [`flex-col`, (0, helpers_1.complete)({ flexDirection: `column` })],
    [`flex-col-reverse`, (0, helpers_1.complete)({ flexDirection: `column-reverse` })],
    [`flex-wrap`, (0, helpers_1.complete)({ flexWrap: `wrap` })],
    [`flex-wrap-reverse`, (0, helpers_1.complete)({ flexWrap: `wrap-reverse` })],
    [`flex-nowrap`, (0, helpers_1.complete)({ flexWrap: `nowrap` })],
    [`flex-auto`, (0, helpers_1.complete)({ flexGrow: 1, flexShrink: 1, flexBasis: `auto` })],
    [`flex-initial`, (0, helpers_1.complete)({ flexGrow: 0, flexShrink: 1, flexBasis: `auto` })],
    [`flex-none`, (0, helpers_1.complete)({ flexGrow: 0, flexShrink: 0, flexBasis: `auto` })],
    [`overflow-hidden`, (0, helpers_1.complete)({ overflow: `hidden` })],
    [`overflow-visible`, (0, helpers_1.complete)({ overflow: `visible` })],
    [`overflow-scroll`, (0, helpers_1.complete)({ overflow: `scroll` })],
    [`absolute`, (0, helpers_1.complete)({ position: `absolute` })],
    [`relative`, (0, helpers_1.complete)({ position: `relative` })],
    [`italic`, (0, helpers_1.complete)({ fontStyle: `italic` })],
    [`not-italic`, (0, helpers_1.complete)({ fontStyle: `normal` })],
    [`oldstyle-nums`, fontVariant(`oldstyle-nums`)],
    [`small-caps`, fontVariant(`small-caps`)],
    [`lining-nums`, fontVariant(`lining-nums`)],
    [`tabular-nums`, fontVariant(`tabular-nums`)],
    [`proportional-nums`, fontVariant(`proportional-nums`)],
    [`font-thin`, (0, helpers_1.complete)({ fontWeight: `100` })],
    [`font-100`, (0, helpers_1.complete)({ fontWeight: `100` })],
    [`font-extralight`, (0, helpers_1.complete)({ fontWeight: `200` })],
    [`font-200`, (0, helpers_1.complete)({ fontWeight: `200` })],
    [`font-light`, (0, helpers_1.complete)({ fontWeight: `300` })],
    [`font-300`, (0, helpers_1.complete)({ fontWeight: `300` })],
    [`font-normal`, (0, helpers_1.complete)({ fontWeight: `normal` })],
    [`font-400`, (0, helpers_1.complete)({ fontWeight: `400` })],
    [`font-medium`, (0, helpers_1.complete)({ fontWeight: `500` })],
    [`font-500`, (0, helpers_1.complete)({ fontWeight: `500` })],
    [`font-semibold`, (0, helpers_1.complete)({ fontWeight: `600` })],
    [`font-600`, (0, helpers_1.complete)({ fontWeight: `600` })],
    [`font-bold`, (0, helpers_1.complete)({ fontWeight: `bold` })],
    [`font-700`, (0, helpers_1.complete)({ fontWeight: `700` })],
    [`font-extrabold`, (0, helpers_1.complete)({ fontWeight: `800` })],
    [`font-800`, (0, helpers_1.complete)({ fontWeight: `800` })],
    [`font-black`, (0, helpers_1.complete)({ fontWeight: `900` })],
    [`font-900`, (0, helpers_1.complete)({ fontWeight: `900` })],
    [`include-font-padding`, (0, helpers_1.complete)({ includeFontPadding: true })],
    [`remove-font-padding`, (0, helpers_1.complete)({ includeFontPadding: false })],
    // not sure if RN supports `max-width: none;`, but this should be equivalent
    [`max-w-none`, (0, helpers_1.complete)({ maxWidth: `99999%` })],
    [`text-left`, (0, helpers_1.complete)({ textAlign: `left` })],
    [`text-center`, (0, helpers_1.complete)({ textAlign: `center` })],
    [`text-right`, (0, helpers_1.complete)({ textAlign: `right` })],
    [`text-justify`, (0, helpers_1.complete)({ textAlign: `justify` })],
    [`text-auto`, (0, helpers_1.complete)({ textAlign: `auto` })],
    [`underline`, (0, helpers_1.complete)({ textDecorationLine: `underline` })],
    [`line-through`, (0, helpers_1.complete)({ textDecorationLine: `line-through` })],
    [`no-underline`, (0, helpers_1.complete)({ textDecorationLine: `none` })],
    [`uppercase`, (0, helpers_1.complete)({ textTransform: `uppercase` })],
    [`lowercase`, (0, helpers_1.complete)({ textTransform: `lowercase` })],
    [`capitalize`, (0, helpers_1.complete)({ textTransform: `capitalize` })],
    [`normal-case`, (0, helpers_1.complete)({ textTransform: `none` })],
    [`w-auto`, (0, helpers_1.complete)({ width: `auto` })],
    [`h-auto`, (0, helpers_1.complete)({ height: `auto` })],
    [`basis-auto`, (0, helpers_1.complete)({ flexBasis: `auto` })],
    [`flex-basis-auto`, (0, helpers_1.complete)({ flexBasis: `auto` })],
    // default box-shadow implementations
    [
        `shadow-sm`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 1, height: 1 },
            shadowColor: `#000`,
            shadowRadius: 1,
            shadowOpacity: 0.025,
            elevation: 1,
        }),
    ],
    [
        `shadow`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 1, height: 1 },
            shadowColor: `#000`,
            shadowRadius: 1,
            shadowOpacity: 0.075,
            elevation: 2,
        }),
    ],
    [
        `shadow-md`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 1, height: 1 },
            shadowColor: `#000`,
            shadowRadius: 3,
            shadowOpacity: 0.125,
            elevation: 3,
        }),
    ],
    [
        `shadow-lg`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 1, height: 1 },
            shadowColor: `#000`,
            shadowOpacity: 0.15,
            shadowRadius: 8,
            elevation: 8,
        }),
    ],
    [
        `shadow-xl`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 1, height: 1 },
            shadowColor: `#000`,
            shadowOpacity: 0.19,
            shadowRadius: 20,
            elevation: 12,
        }),
    ],
    [
        `shadow-2xl`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 1, height: 1 },
            shadowColor: `#000`,
            shadowOpacity: 0.25,
            shadowRadius: 30,
            elevation: 16,
        }),
    ],
    [
        `shadow-none`,
        (0, helpers_1.complete)({
            shadowOffset: { width: 0, height: 0 },
            shadowColor: `#000`,
            shadowRadius: 0,
            shadowOpacity: 0,
            elevation: 0,
        }),
    ],
];
exports.default = defaultStyles;
function fontVariant(type) {
    return {
        kind: `dependent`,
        complete(style) {
            if (!style.fontVariant || !Array.isArray(style.fontVariant)) {
                style.fontVariant = [];
            }
            style.fontVariant.push(type);
        },
    };
}
