"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isObject = exports.isString = exports.Unit = exports.ConfigType = exports.isOrientation = exports.ORIENTATIONS = exports.isPlatform = exports.PLATFORMS = void 0;
exports.PLATFORMS = [`ios`, `android`, `windows`, `macos`, `web`];
function isPlatform(x) {
    return exports.PLATFORMS.includes(x);
}
exports.isPlatform = isPlatform;
exports.ORIENTATIONS = [`portrait`, `landscape`];
function isOrientation(x) {
    return exports.ORIENTATIONS.includes(x);
}
exports.isOrientation = isOrientation;
var ConfigType;
(function (ConfigType) {
    ConfigType["fontSize"] = "fontSize";
    ConfigType["lineHeight"] = "lineHeight";
})(ConfigType = exports.ConfigType || (exports.ConfigType = {}));
var Unit;
(function (Unit) {
    Unit["rem"] = "rem";
    Unit["em"] = "em";
    Unit["px"] = "px";
    Unit["percent"] = "%";
    Unit["vw"] = "vw";
    Unit["vh"] = "vh";
    Unit["none"] = "<no-css-unit>";
})(Unit = exports.Unit || (exports.Unit = {}));
function isString(value) {
    return typeof value === `string`;
}
exports.isString = isString;
function isObject(value) {
    return typeof value === `object`;
}
exports.isObject = isObject;
